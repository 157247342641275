import ERC20ABI from './ERC20.abi.json'
import AbachiVestingABI from './AbachiVesting.abi.json'
import SABI from './SABI.abi.json'

export const erc20ABI = ERC20ABI
export const abachiVestingABI = AbachiVestingABI
export const sabiABI = SABI

export const alphaAbachiAddress = '0x835BA011Ec70e695879185dF3d46939DBfBeF7E5'
export const sabiAddress = '0x8D5A0ee82A873645Cfd6C733CA7D93aA79B91bDF'
export const pabiAddress = '0xEbc0616E3454b4532C14450359092A8cc42c6a61'
export const presaleAddress = '0x62Fb6F77cebc8500448FbFB8D4F875f01d01531d'
export const vestingAddress = '0x5dfF5932b6a28D5A5F9322DEfa4212aE99931941'
export const redeemAddress = {
  11155111: "0x5A0706a7D30FCeA295A9Ce934D42B7Cac45810DA",
  1: "0x338a775150b0E928Be35DB3694B40CD837d9841D"
};
export const ABIAddress = {
  11155111:  "0x39Bd973baDB15Ebe94023440711ADB9e484cDca6",
  1: "0xBF0B8b7475EdB32D103001Efd19FdD2753d7B76D"
}; 

export const USDCAddress = "0x1439bCE5AfD86e2AA2C34489ad3ec88B9b3CE51F"; // disregard this -- used directly in contract



export function ellipseAddress(address = "", width = 6) {
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export const POLLING_INTERVAL = 5000;

export const CHAIN_ID = 80001;

export const CHAIN_INFO = {
  80001: {
    "name": "Polygon Testnet Mumbai",
    "chain": "Polygon",
    "network": "testnet",
    "rpc": [
      "https://matic-mumbai.chainstacklabs.com",
      "https://rpc-mumbai.maticvigil.com",
      "https://matic-testnet-archive-rpc.bwarelabs.com"
    ],
    "faucets": [
      "https://faucet.polygon.technology"
    ],
    "nativeCurrency": {
      "name": "MATIC",
      "symbol": "MATIC",
      "decimals": 18
    },
    "infoURL": "https://polygon.technology",
    "shortName": "maticmum",
    "chainId": 80001,
    "networkId": 80001,
    "explorers": [
      {
        "name": "polygonscan",
        "url": "https://mumbai.polygonscan.com",
        "standard": "EIP3091"
      }
    ]
  },
  137: {
    "name": "Polygon Mainnet",
    "chain": "Polygon",
    "network": "mainnet",
    "rpc": [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.matic.network",
      "https://matic-mainnet.chainstacklabs.com",
      "https://rpc-mainnet.maticvigil.com",
      "https://rpc-mainnet.matic.quiknode.pro",
      "https://matic-mainnet-full-rpc.bwarelabs.com"
    ],
    "faucets": [

    ],
    "nativeCurrency": {
      "name": "MATIC",
      "symbol": "MATIC",
      "decimals": 18
    },
    "infoURL": "https://polygon.technology",
    "shortName": "MATIC",
    "chainId": 137,
    "networkId": 137,
    "slip44": 966,
    "explorers": [
      {
        "name": "polygonscan",
        "url": "https://polygonscan.com",
        "standard": "EIP3091"
      }
    ]
  },
  11155111: {
    explorers: [{
      name: 'sepoliascan',
      "url": "https://sepolia.etherscan.io",
      standard: 'EIP3091'
    }]
  },
  1: {
    explorers: [{
      name: 'mainnetscan',
      "url": "https://etherscan.io",
      standard: 'EIP3091'
    }]
  }
}

export const RPC_URLS = {
  80001: 'https://polygon-mumbai.g.alchemy.com/v2/sAMvjMQb9w57_kCGnwWfnwupEv-u6Mah',
  137: 'https://polygon-mainnet.g.alchemy.com/v2/h2AuPW4DbJykjbZdYTozxcT-eM6y0WIj',
};

export const ETHERSCANS = {
  '137': 'https://polygonscan.com',
  '80001': 'https://mumbai.polygonscan.com',
}
