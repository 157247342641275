
import { useAppKit } from '@reown/appkit/react'
import plusCircleIcon from "../../Assets/images/plus-circle.svg";
import { Button } from 'reactstrap';

export default function ConnectButton() {
  // 4. Use modal hook
  const { open } = useAppKit()

  return (

    <Button
      id="caret"
      color=""
      className="connect-wallet-btn btn-primary sm:px-0 px-5"
      onClick={() => open()}
    >
      <img src={plusCircleIcon} className="mr-2" />
      Connect Wallet
    </Button>

  )
}