import './Assets/css/index.scss';
import './Assets/vendor/bootstrap.min.css'

import AbachiPresale from './Pages/app/index.js';

import { createAppKit } from '@reown/appkit/react'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { mainnet, sepolia } from '@reown/appkit/networks'

// 1. Get projectId
const projectId = '4427862e309ccb602a22d8e80c73ca52';

// 2. Set the networks
const networks = [ mainnet, sepolia];

// 3. Create a metadata object - optional
const metadata = {
  name: 'abi-redeem',
  description: 'Redeem ABI',
  url: 'https://redeem.abachi.io', // origin must match your domain & subdomain
  icons: ['https://assets.reown.com/reown-profile-pic.png']
}

// 4. Create a AppKit instance
createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId,
  features: {
    email: false, // Optional - defaults to your Cloud configuration
    socials: false, // Optional - defaults to your Cloud configuration
    analytics: false, // Optional - defaults to your Cloud configuration
    swaps: false, // Optional - defaults to your Cloud configuration
    onramp: false // Optional - defaults to your Cloud configuration
  },
  enableCoinbase: false,
  includeWalletIds: [ "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96" ]
})

function App() {
  return (
    <div className="app">
      <AbachiPresale/>
    </div>
  );
}

export default App;
