import React, {useState} from 'react';
import {Blockie} from "../Blockie";
import {CustomTitleToolTip} from "../CustomTitleToolTip"
import Logo from "../../Assets/images/abachi_logo.png";
import rightArrowIcon from "../../Assets/images/right-arrow.svg";
import classNames from "classnames";

import { useAppKit } from '@reown/appkit/react'

export const Header = ({address, connected, ellipseAddress, whitelistStatus}) => {
    const [toggle, setToggle] = useState(false);
    const { open } = useAppKit();

    const toggleCls = classNames('navbar-toggler',
        {'collapsed': !toggle}
    )
    const mobileMenuWrapperCls = classNames(
        {'show': toggle}
    )

    const handleToggleMenu = (e) => {
        setToggle(!toggle)
    }
    return (
        <div>
            <div className="d-flex align-items-center py-2 my-2">
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <img src={Logo} alt="logo" className="abachi-logo"/>
                    <div className='sm-block hidden'>
                        {connected && address ?
                            <CustomTitleToolTip className="w-50" tip={address} header={
                                <div className="d-flex justify-content-between align-items-end invoice-header align-items-center mb-2 hand"
                                    onClick={() => open({ view: "Account"})}>
                                    <Blockie address={address}/>
                                    <div className='mx-2 sm-block hidden'>
                                        {ellipseAddress(address)}
                                    </div>
                                    <img src={rightArrowIcon} />
                                </div>
                            }/> : null }
                    </div>
                </div>
            </div>
        </div>
    );
}
