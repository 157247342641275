import React, { useEffect, useState } from "react";
import {
  Button,
} from "reactstrap";
import { Blockie } from "../../Components/Blockie";
import { CustomTitleToolTip } from "../../Components/CustomTitleToolTip";

// import { ConnectWalletRender } from "../../Components/ConnectWallet";
import { fromWei } from "web3-utils";
import { ethers } from "ethers";

import {
  ellipseAddress,
  erc20ABI,
  abachiVestingABI,
  CHAIN_INFO,
  ABIAddress,
  redeemAddress
} from "../../Utils/index";
import githubLogo from "../../Assets/images/github_filled.svg"
import twitterLogo from "../../Assets/images/twitter_circle_filled.svg"
import carboLogo from "../../Assets/images/carbon_logo_discord.svg"
import mailLogo from "../../Assets/images/mail_circle.svg"
import approveIcon from "../../Assets/images/approve-icon.svg"
import errorIcon from '../../Assets/images/error-icon.svg'

import arrowDownIcon from "../../Assets/images/arrow.svg"

import lockIcon from "../../Assets/images/lock-icon.svg";

import { Header } from "../../Components/Header";


import ConnectButton from "../../Components/ConnectWallet/connectButton";

import { useAppKitAccount, useAppKitProvider, useAppKitNetwork } from "@reown/appkit/react";
import { BrowserProvider } from "ethers";
import moment from "moment";


function getCountDown(timer) {
  const countDown = {
    days: Math.floor(moment(timer).diff(moment(), 'days')),
    hours: Math.floor(moment(timer).diff(moment(), 'hours') % 24),
    minutes: Math.floor(moment(timer).diff(moment(), 'minutes') % 60),
    seconds: Math.floor(moment(timer).diff(moment(), 'seconds') % 60)
  };
  return countDown;
}

export default function AbachiPresale() {

  const deadline = moment("2025-02-28 11:59:59").valueOf();

  const { address, isConnected } = useAppKitAccount();
  const { chainId } = useAppKitNetwork();
  const { walletProvider } = useAppKitProvider("eip155");
  const [tx, setTx] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [ABIBalance, setABIBalance] = useState(0);
  const [approvedBalance, setApprovedBalance] = useState(0);
  const [needsApproval, setNeedsApproval] = useState(false);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [countDown, setCountDown] = useState(getCountDown(deadline));
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(getCountDown(deadline));
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  const approve = async () => {
    console.log("approve")
    try {
      const contract = new ethers.Contract(ABIAddress[chainId], erc20ABI, signer);
      console.log(contract, signer);
      const tx = await contract.approve(
        redeemAddress[chainId],
        ethers.parseEther(ABIBalance.toString())
      );
      console.log(tx);
      setTx(tx);
      setReceipt(null);
      setError(false);
      const receipt = await tx.wait();
      setReceipt(receipt);
      setApprovedBalance(ABIBalance);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const redeem = async () => {
    try {
      const contract = new ethers.Contract(redeemAddress[chainId], abachiVestingABI, signer);
      const tx = await contract.swap();
      setTx(tx);
      setReceipt(null);
      setError(false);
      const receipt = await tx.wait();
      setReceipt(receipt);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (!isConnected) return;

    async function getApprovalStatus() {

      const provider = new BrowserProvider(walletProvider);
      const signer = provider && await provider.getSigner();
      const contract = new ethers.Contract(ABIAddress[chainId], erc20ABI, signer);
      const redeemContract = new ethers.Contract(redeemAddress[chainId], abachiVestingABI, signer);
      const actualBalance = await contract.allowance(
        address,
        redeemAddress[chainId]
      );
      const divider = await redeemContract.RATIO_DIVISOR();
      const swapRatio = await redeemContract.swapRatio();

      const abiBalanceinWei = await contract.balanceOf(address);
      const abiBalance = fromWei(abiBalanceinWei.toString(), "ether");
      const approvedBalance = fromWei(
        actualBalance.toString(),
        "ether"
      );



      console.log(approvedBalance, "approved balance")

      const needsApproval =
        parseFloat(abiBalance) > parseFloat(approvedBalance)
          ? true
          : false;

      console.log(needsApproval, "needs approval", abiBalance, approvedBalance)
      setApprovedBalance(parseFloat(approvedBalance) || 0);
      setNeedsApproval(needsApproval);
      setABIBalance(parseFloat(abiBalance) || 0);
      setProvider(provider);
      setSigner(signer);
      setCurrentIndex(parseFloat(swapRatio) / parseFloat(divider));
      console.log(swapRatio, divider, "currentIndex", currentIndex)
    }

    getApprovalStatus();
  }, [isConnected, address, approvedBalance, chainId, receipt]);


  return (
    <div className="main-page">

      <div className="top-section">
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />
        <div className="container">
          <Header
            address={address}
            connected={isConnected}
            ellipseAddress={ellipseAddress}
          />


          <div className="hero-section">
            <div className="header">Redeem ABI</div>
            {!address && (
              <div className="text-center sub-text my-4 mx-auto">
                <div>
                  Connect your wallet to redeem ABI
                  <div>
                    <div className="mt-4">
                      {isConnected && address ? (
                        <CustomTitleToolTip
                          className="w-50"
                          tip={address}
                          header={
                            <div
                              className="sm-hidden block d-flex justify-content-between align-items-end invoice-header align-items-center mb-2"
                              onClick={this.toggleAccountInfoModal}
                            >
                              <Blockie address={address} />
                              <div className="ml-2">
                                {ellipseAddress(address)}
                              </div>
                            </div>
                          }
                        />
                      ) : <ConnectButton />}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {address && (
              <div className="hero-section">
                <div className="box">
                  <div className="p25">
                    <div className="small-label text-start pb-3 title-text">
                      Your ABI Balance
                    </div>
                    <div className="pb-3">
                      <input
                        className="text-box py-2 px-3"
                        placeholder="ABI Balance"
                        // defaultValue={ABIBalance}
                        type="number"
                        value={ABIBalance.toFixed(2)}
                        readOnly
                      />
                    </div>
                    <div>
                      <img
                        src={arrowDownIcon}
                        className="my-2"
                        alt="approveIcon"
                      />
                    </div>
                    <div className="small-label text-start pb-3 title-text">
                      USDC
                    </div>
                    <div className="pb-3 mb-3">
                      <input
                        className="text-box py-2 px-3"
                        placeholder="USDC Value"
                        value={(ABIBalance * currentIndex).toFixed(2)}
                        type="number"
                        readOnly
                      />
                    </div>
                    <div className="d-none d-sm-block">
                      <div className="d-flex justify-content-between ">
                        {
                          needsApproval && (
                            <Button
                              color=""
                              className="btn-primary buysell-btn"
                              onClick={approve}>
                              <img
                                src={approveIcon}
                                className="mr-2"
                                alt="approveIcon"
                              />
                              Approve
                            </Button>
                          )
                        }
                        {
                          !needsApproval && !!ABIBalance && (
                            <Button color="" className="btn-primary buysell-btn" onClick={redeem}>
                              <img
                                src={lockIcon}
                                className="mr-2"
                                alt="approveIcon"
                              />
                              Redeem
                            </Button>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-block d-sm-none">
                  <div className="d-flex justify-content-between ">
                    {/* <Button
                                            color=""
                                            className="btn-primary buysell-btn"
                                            onClick={() => {
                                                window.open(this.state.sellLink, "_blank");
                                            }}>
                                            <img src={sellIcon} className="mr-2" alt="approveIcon" />
                                            Sell
                                        </Button> */}
                    {/* <div className="px-3"></div> */}
                    {
                      !needsApproval && !!ABIBalance && (
                        <Button color="" className="btn-primary buysell-btn" onClick={() => {
                          redeem();
                        }}>
                          <img src={lockIcon} className="mr-2" alt="approveIcon" />
                          Redeem
                        </Button>)
                    }
                  </div>
                </div>
                <div className="font-16 mt-4 d-flex flex-row justify-content-around align-items-center font-weight-bold">
                  <div className="p-4">
                    {countDown.days}<div className="font-weight-normal">days</div>
                  </div>
                  <div className="p-4">
                    {countDown.hours}<div className="font-weight-normal">hours</div>
                  </div>
                  <div className="p-4">
                    {countDown.minutes}<div className="font-weight-normal">minutes</div>
                  </div>
                  <div className="p-4">
                    {countDown.seconds}<div className="font-weight-normal">seconds</div>
                  </div>
                  <div className="p-4 font-weight-normal">
                    to Redeem ABI
                  </div>

                </div>
              </div>
            )}

            <div className="font-16 mt-4">
              {tx ? (
                <div className="message text-neutral-1 d-flex align-items-center justify-content-center">
                  <p className="tx-wrap">TX Hash (pending): </p>
                  <a
                    target="_blank"
                    className="tx-text"
                    rel="noreferrer"
                    href={`${CHAIN_INFO[chainId].explorers[0].url}/tx/${tx.hash}`}
                  >{`${CHAIN_INFO[chainId].explorers[0].url}/tx/${tx.hash}`}</a>
                </div>
              ) : null}
              {receipt ? (
                <div className="message text-neutral-1 mt-2 d-flex align-items-center justify-content-center">
                  <p className="tx-wrap">TX Receipt (confirmed): </p>
                  <a
                    target="_blank"
                    className="tx-text"
                    rel="noreferrer"
                    href={`${CHAIN_INFO[chainId].explorers[0].url}/tx/${receipt.hash}`}
                  >{`${CHAIN_INFO[chainId].explorers[0].url}/tx/${receipt.hash}`}</a>
                </div>
              ) : null}
            </div>
            {error ? (
              <div className="error-message2 mw-100 hand-pointer">
                <img src={errorIcon} className="mr-2" alt="errorIcon" />{" "}
                {error}{" "}
              </div>
            ) : null}
          </div>

          <div className="hero-footer d-flex mt-4">
            <a
              href="https://github.com/abachi-io"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={githubLogo}
                className="cursor-pointer"
                alt="githubLogo"
              />
            </a>
            <a
              href="https://twitter.com/Abachi_io"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={twitterLogo}
                className="cursor-pointer"
                alt="twitterLogo"
              />
            </a>
            <a
              href="https://discord.gg/abachi"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={carboLogo}
                className="cursor-pointer"
                alt="carboLogo"
              />
            </a>
            <a href="mailto:info@abachi.io" rel="noreferrer">
              <img src={mailLogo} className="cursor-pointer" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

